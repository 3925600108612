.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999999;
  &.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
  }

  &.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
  }

  &.top-left {
    top: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
  }

  &.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
  }

  &.top-center {
    left: 50%;
    top: 10px;
    transform: translate(-50%, 0);
  }
}

.notification {
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 0 12px #999;
  padding: 15px 15px 15px 15px;
  color: #fff;
  z-index: 99999999;
  p{
    margin:0;
  }
  button {
    background: transparent;
    padding: 0;
    margin: 0 10px 0 0;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
  }
}