.connect-wal {
  a {
    text-align: center;
    color: #fff !important;
    background: #aa34ff;
    border-radius: 30px;
    outline: 0;
    font-weight: 800;
    text-decoration: none;
    padding: 6px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0 rgb(131 100 226 / 0%);
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 2px 2px 20px 0 #aa34ff;
      transition: all 0.3s ease;
    }
  }
}
